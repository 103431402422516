@tailwind base;
@tailwind components;
@tailwind utilities;

.noto-sans-mono-regular {
  font-family: "Noto Sans Mono", monospace;
  font-weight: 400;
  font-style: normal;
}
.permanent-marker-regular {
  font-family: "Permanent Marker", cursive;
  font-weight: 400;
  font-style: normal;
}
.rock-salt-regular {
  font-family: "Rock Salt", cursive;
  font-weight: 400;
  font-style: normal;
}
body, .poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

:root {
  --foreground-color: rgb(2, 6, 23);
  --background-color: rgb(203, 213, 225);
  .blob{
    @apply fill-sky-700;
  }
}

:root.dark {
  --foreground-color: rgb(203, 213, 225);
  --background-color: rgb(2, 6, 23);
  .blob{
    @apply fill-sky-300;
  }
}

body {
  margin: 0;
  color: var(--foreground-color);
  background: var(--background-color);
  @apply min-h-[100dvh] text-base tracking-wider relative;
}

.border-color{
  border-color: var(--foreground-color);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.blob-container{
  z-index: -1;
  @apply w-full min-h-[100dvh] absolute left-0 top-0;
}

.blob {
  animation: move1 30s ease-in-out infinite;
  transform-origin: 50% 50%;
  filter: blur(100px);
  @apply absolute;
}

@keyframes move1 {
  0%   { transform: scale(1)   translate(10px, -30px); }
  38%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  40%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  78%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  80%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  100% { transform: scale(1)   translate(10px, -30px); }
}

.go-up-animation{
  animation: move-up 900ms ease-in-out;
}

@keyframes move-up {
  from{
    transform: translate(0, 20vh);
    opacity: 0;
  }
  to{
    transform: translate(0, 0);
    opacity: 1;
  }
}